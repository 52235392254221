<template>
  <div class="form-container">
    <el-form :inline="true" ref="formData" :model="formData" label-width="80px">
      <el-form-item label="所属行业">
        <el-select v-model="formData.trade" placeholder="请选择所属行业">
          <el-option
            v-for="item in tradeOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="诊断机构">
        <el-input
          v-model="formData.company"
          placeholder="请输入诊断机构"
          class="long-input"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button class="primaryBtn1" @click="handleSearch">查询</el-button>
        <el-button class="primaryBtn1" @click="exportDiagnose"
          >导出四化诊断进度</el-button
        >
        <el-button class="primaryBtn1 orange-btn" @click="handleDownload"
          >人员备案下载</el-button
        >
      </el-form-item>
      <el-form-item>
        <el-upload
          :show-file-list="false"
          :file-list="companyFileList"
          :action="uploadUrl"
          :on-success="handleSignFileSuccess"
          :on-preview="handleSignFilePreview"
          :on-exceed="handleSignFileExceed"
          :on-remove="handleSignFileRemove"
          :on-change="handleSignFileChange"
          :accept="'.xls,.xlsx'"
        >
          <el-button class="primaryBtn1">导入规上企业</el-button>
          <el-button class="primaryBtn1" @click.stop="handleDownloadTemplate"
            >下载模板</el-button
          >
        </el-upload>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
// import axios from "axios";
import configs from "@/config/index";
import { importCompany } from "@/api/zf";
export default {
  props: {
    formData: {
      type: Object,
      default: () => {},
    },
    tradeOptions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      uploadUrl: "",
      loadingStatus: null,
      companyFileList: [],

      tempFile: null,
    };
  },
  mounted() {
    this.uploadUrl = `${configs.baseUrl2}/api/v1/common/upload`;
    console.log(this.uploadUrl, "uploadUrl");
  },
  methods: {
    handleDownloadTemplate() {
      let linkDom = document.createElement("a");
      linkDom.href =
        "https://suizz.ceprei.com:9204/txt/规上企业导入模板_1737525388045.xlsx";
      // linkDom.href =
      // "https://www.szz.manage.beifen.hcxtec.com/files/txt/规上企业导入模板_1737450154476.xlsx";
      linkDom.target = "_blank";
      linkDom.download = "规上企业导入模板.xlsx";
      linkDom.style.display = "none";
      linkDom.click();
    },
    handleSignFileChange(file, fileList) {
      this.tempFile = file;
      // console.log("handleSignFileChange", file, fileList);
      if (file.status == "uploading") {
        this.loadingStatus = this.$loading({
          lock: true,
          text: "上传中...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
      } else if (file.status == "ready") {
        // console.log("do nothing");
        this.loadingStatus = this.$loading({
          lock: true,
          text: "上传中...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
      } else {
        if (file.status != "success") {
          file.status = "error";
        }
        this.loadingStatus.close();
      }
    },
    handleSignFileSuccess(response) {
      console.log("handleSignFileSuccess", response);
      if (response.code == 200) {
        console.log(response, "response---====");

        this.companyFileList.push({
          name: response.originalFilename,
          url: response.url,
        });
        // this.form.sign_file = JSON.stringify(this.companyFileList);
        let formData = new FormData();
        formData.append("file", this.tempFile.raw);
        console.log(formData, "formData-----======");
        importCompany(formData).then((res) => {
          console.log("importCompany", res);
          if (res.code == 200) {
            this.$message.success("导入成功");
          } else {
            this.$message.error(res.msg);
          }
        });
      } else {
        if (response.msg == "Uploaded file format is limited") {
          this.$message.error("不符合文件上传格式");
        } else {
          this.$message.error(response.msg);
        }
      }
    },
    handleSignFileExceed() {
      // this.$message.error("最多只能上传1个文件");
    },
    handleSignFilePreview(file) {
      if (!file.status) {
        this.$message.error("文件获取失败");
      }
      let linkDom = document.createElement("a");

      if (file.url.indexOf("http") == -1) {
        linkDom.href = `${configs.baseUrl}${file.url}`;
      } else {
        linkDom.href = file.url;
      }
      linkDom.target = "_blank";
      linkDom.download = file.name;
      linkDom.style.display = "none";
      linkDom.click();
    },
    handleSignFileRemove(file, fileList) {
      console.log("handleSignFileRemove", file, fileList);
      this.companyFileList = fileList.map((item) => {
        return {
          name: item.name,
          url: item.url,
        };
      });
    },

    exportDiagnose() {
      const url =
        configs.baseUrl +
        "/index/government/exportStep?token=" +
        sessionStorage.getItem("token");
      const link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    handleSearch(v) {
      this.$emit("formSearch", v);
    },
    handleDownload(v) {
      console.log("点击人员备案下载");
      this.$emit("formDownload", v);
    },
  },
};
</script>

<style lang="less" scoped>
.form-container {
  .long-input {
    width: 300px;
  }

  .orange-btn {
    background-color: #ff8f1f !important;
    border-color: #ff8f1f !important;
  }
}
</style>
